<template>
  <div class="alert-wrapper">
    <div v-for="(alert, index) in alerts" :key="index">
      <v-alert
        class="alert-bar"
        :class="{ 'alert-hidden': !alert.show || alert.close, 'alert-visible': alert.show }"
        dismissible
        :type="alert.type"
        border="top"
        rounded="t-0"
      >
        <code v-if="alert.data.code">{{ alert.data.code }}</code>
        <span class="ml-2">{{ alert.data.message }}</span>
        <div v-for="(error, name, index) in getErrorsText" :key="index">
          <span class="font-weight-bold">{{ name }}</span>
          <span>: {{ error }}</span>
        </div>
      </v-alert>
    </div>
    <div v-for="progress in progressData" :key="progress.id">
      <v-alert v-if="progress.visible" class="alert-bar" type="info">
        <p v-html="progress.title"></p>
        <p>
          <small>{{ progress.statusMessage }} ({{ progress.percents }}%)</small>
        </p>
        <v-progress-linear
          v-if="progress.status !== 2"
          :color="progress.status === 3 ? 'red' : 'light-green'"
          height="10"
          :value="progress.percents"
          striped
          rounded
        ></v-progress-linear>
        <template v-slot:close>
          <v-tooltip v-if="progress.status === 1" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="hideAlert(progress)" v-on="on" v-bind="attrs">mdi-minus-circle</v-icon>
            </template>
            Приховати
          </v-tooltip>
          <v-tooltip v-else top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="hideAlert(progress)" v-on="on" v-bind="attrs">mdi-close-circle</v-icon>
            </template>
            Закрити
          </v-tooltip>
        </template>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AlertMain',

  data: () => ({
    alerts: [],
    lastPush: null,
  }),

  computed: {
    ...mapState('logs', ['alertType', 'alertData', 'progressData']),
    ...mapGetters('logs', ['getErrorsText']),
  },

  watch: {
    alertData(val) {
      this.alerts.push({ type: this.alertType, data: val, show: false })
      setTimeout(() => (this.alerts[this.alerts.length - 1].show = true), 1)
      if (this.lastPush) {
        clearTimeout(this.lastPush)
      }
      this.lastPush = setTimeout(() => this.clearAll(), 2500)
    },
  },

  methods: {
    ...mapActions('logs', ['updateProgressItem', 'deleteProgressItem']),

    clearAll() {
      const interval = 200
      const intervalForClear = 200 * this.alerts.length
      this.alerts.forEach((item, index) => setTimeout(() => (item.show = item.type === 'error'), interval * (index + 1)))
      setTimeout(() => (this.alerts = [...this.alerts.filter((item) => item.type === 'error')]), intervalForClear)
    },

    hideAlert(progressItem) {
      if (progressItem.status === 1) {
        progressItem.visible = false
        this.updateProgressItem(progressItem)
      } else {
        this.deleteProgressItem({ id: progressItem.id })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.alert {
  &-wrapper {
    max-width: 40%;
    top: 4px;
    right: 4px;
    position: fixed;
    z-index: 9999;
  }
}
.alert-bar {
  width: fit-content;
  float: right;
  text-wrap: normal;
  &.alert-hidden {
    right: -2000px;
    transition: all 0.5s ease;
  }

  &.alert-visible {
    right: 0;
    transition: all 0.5s ease;
  }
}
</style>
